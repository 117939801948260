import React, { forwardRef, useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, List, ListItem } from '@material-ui/core';
import { KeyboardArrowDown as ExpandMoreIcon, KeyboardArrowUp as ExpandLessIcon } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import find from 'lodash/find';
import each from 'lodash/each';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.icon,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.background.contrastText,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.background.contrastText
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));


const getChildMenu = (page, classes, toggleSideBar) => {
  return page.children.map(childPage => {
      return (
        <ListItem
          component="div"
          className={classes.nested}
          key={childPage.title}
        >
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            onClick={toggleSideBar}
            to={childPage.href}
          >
            <div className={classes.icon}>{childPage.icon}</div>
            {childPage.title}
          </Button>
        </ListItem>
      );
    }
  );
};

const SidebarNav = props => {
  const { pages, className, open, toggleSideBar, ...rest } = props;

  const classes = useStyles();
  const [menuPages, setPages] = useState(pages);
  const [navItemExpand, setNavItemExpand] = useState({});

  useEffect(() => {
    let href = window.location.pathname;
    each(menuPages, page => {
      if (find(page.children, { href: href })) {
        setNavItemExpand(navItemExpand => ({
          ...navItemExpand,
          [page.title]: true
        }));
      }
    });
  }, [menuPages]);

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {
        menuPages.map(page => {
            return (
              <ListItem
                className={classes.item}
                disableGutters
                key={page.title}
              >
                {
                  !page.children ?
                    <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      onClick={() => {
                        // toggleSideBar();
                      }}
                      to={page.href}
                    >
                      <div className={classes.icon}>{page.icon}</div>
                      {page.title}
                    </Button>
                    :
                    <div>
                      {
                        page.children ?
                          <React.Fragment>
                            <Button
                              className={classes.button}
                              onClick={() => {
                                setNavItemExpand(navItemExpand => ({
                                  ...navItemExpand,
                                  [page.title]: !navItemExpand[page.title]
                                }));
                              }}
                            >
                              <div className={classes.icon}>{page.icon}</div>
                              {page.title}
                              {navItemExpand[page.title] ? open ? <ExpandLessIcon/> : null : open ?
                                <ExpandMoreIcon/> : null}
                            </Button>
                            <Collapse in={navItemExpand[page.title]}>
                              <List
                                component="div"
                                disablePadding
                              >
                                {getChildMenu(page, classes, toggleSideBar)}
                              </List>
                            </Collapse>
                          </React.Fragment>
                          :
                          null
                      }
                    </div>
                }
              </ListItem>
            );
          }
        )
      }
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
