import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Background, Parallax } from 'react-parallax';
import { Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: -200
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingLeft: '10%',
    paddingRight: '10%'
  },
  altRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingLeft: '10%',
    paddingRight: '10%',
    backgroundColor: '#d5dae2',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  contentFull: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  altRowFull: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingLeft: '10%',
    paddingRight: '10%',
    backgroundColor: '#d5dae2',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}));

const MainPage = (props) => {
  const { contentArray, title, subTitle, topImage, fullWidth } = props;
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div>
      <Parallax
        bgImageAlt="the dog"
        strength={300}
      >
        <div style={{ height: 500 }}>
          <div style={{
            display: 'flex',
            flex: 1,
            paddingLeft: '10%',
            paddingRight: '10%',
            height: '100%',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Typography variant={'h3'} style={{ color: '#fff', fontWeight: 'bold' }}>
              {title}
            </Typography>
            <Typography variant={'h4'} style={{ color: '#fff', fontWeight: 'bold' }}>
              {subTitle}
            </Typography>
          </div>
        </div>
        <Background>
          <img src={topImage} height={800} alt={'background'}/>
        </Background>
      </Parallax>
      <div style={{ width: '100%' }}>
        {
          contentArray.map((content, contentIndex) => {
            if (contentIndex % 2 === 0) {
              return (
                <div key={`content${contentIndex}`}
                     className={clsx({ [classes.content]: true, [classes.contentFull]: fullWidth === true })}>
                  {content}
                </div>
              );
            } else {
              return (
                <div key={`content${contentIndex}`}
                     className={clsx({ [classes.altRow]: true, [classes.altRowFull]: fullWidth === true })}>
                  {content}
                </div>
              );
            }
          })
        }
      </div>
    </div>
  );
};

MainPage.propTypes = {
  contentArray: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  topImage: PropTypes.string,
  fullWidth: PropTypes.bool
};

export default MainPage;
