import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Hidden, Typography } from '@material-ui/core';
import { Email, Phone } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    backgroundColor: '#050606',
    color: '#fff'
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Hidden smDown>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flexGrow: 1 }}>
            <Typography variant="body1" style={{ color: '#fff' }}>
              &copy;{' '}
              2020, NSD Group, LLC. All Rights Reserved.
            </Typography>
            <Typography variant="caption" style={{ color: '#fff' }}>
              800 West Yamato Road, Suite 100 Boca Raton, FL 33431
            </Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Email style={{ marginRight: 5 }}/>
              <Typography variant="body1" style={{ marginLeft: 5 }}>
                <a style={{ color: '#fff' }}
                   href={'mailto:vprotect@nationsafedrivers.com'}>vprotect@nationsafedrivers.com</a>
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Phone style={{ marginLeft: 15, marginRight: 5 }}/>
              <Typography variant="body1" style={{ marginLeft: 5, color: '#fff' }}><a
                style={{ color: '#fff' }}
                href={'tel:8886849327'}>888-684-9327</a></Typography>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center'
        }}>
          <div style={{ flexGrow: 1 }}>
            <Typography variant="body1" style={{ color: '#fff' }}>
              &copy;{' '}
              2020, NSD Group, LLC. All Rights Reserved.
            </Typography>
            <Typography variant="caption" style={{ color: '#fff' }}>
              800 West Yamato Road, Suite 100 Boca Raton, FL 33431
            </Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Email style={{ marginRight: 5 }}/>
            <Typography variant="body1" style={{ marginLeft: 5 }}>
              <a style={{ color: '#fff' }}
                 href={'mailto:vprotect@nationsafedrivers.com'}>vprotect@nationsafedrivers.com</a>
            </Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Phone style={{ marginLeft: 15, marginRight: 5 }}/>
            <Typography variant="body1" style={{ marginLeft: 5, color: '#fff' }}>888-684-9327</Typography>
          </div>
        </div>
      </Hidden>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
