import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Background, Parallax } from 'react-parallax';
import { Grid, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';

const tinycolor = require('tinycolor2');

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: -200
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingLeft: '10%',
    paddingRight: '10%'
  },
  altRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingLeft: '10%',
    paddingRight: '10%',
    backgroundColor: '#e4e4e4',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}));

const useFactStyles = makeStyles(theme => ({
  factDiv: {
    minHeight: 75,
    // marginTop: -40,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'flex-end',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(7),
    backgroundColor: tinycolor(theme.palette.secondary.light).setAlpha(.5).toRgbString()
  }
}));

const FactDiv = (props) => {
  const classes = useFactStyles();
  return (
    <div style={{ paddingTop: 10 }}>
      <div style={{
        width: 80,
        marginBottom: -55,
        height: 80,
        marginLeft: -30,
        borderRadius: 40,
        backgroundColor: '#4d5c70',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center'
      }}>
        <Typography variant={'h4'} style={{ color: '#fff' }}>Fact</Typography>
      </div>
      <div className={classes.factDiv}>
        {props.children}
      </div>
    </div>
  );
};

const WhyValueProtect = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Parallax
        bgImageAlt="the dog"
        strength={300}
      >
        <div style={{ paddingTop: 50, height: 500 }}>
          <div style={{
            display: 'flex',
            flex: 1,
            height: '100%',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '10%',
            paddingRight: '10%'
          }}>
            <Typography variant={'h3'} style={{ color: '#fff', fontWeight: 'bold' }}>
              Why ValueProtect
            </Typography>
            <Typography variant={'h4'} style={{ color: '#fff', fontWeight: 'bold' }}>
              ValueProtect is a simple and inexpensive way to protect the value of your vehicle.
            </Typography>
          </div>
        </div>
        <Background>
          <img src={'/images/backgrounds/AdobeStock_135739514.jpeg'} height={900} alt={'home background'}/>
        </Background>
      </Parallax>
      <div className={classes.content}>
        <Grid container spacing={4}>
          <Grid item sm={12} md={6}>
            <div style={{ width: '75%' }}>
              <Typography variant={'h4'}>
                ValueProtect is a simple and INEXPENSIVE way to PROTECT the value of your vehicle.
              </Typography>
            </div>
            <Typography variant={'body1'}>
              <div style={{ width: '75%' }}>
                <FactDiv>
                  Accidents happen...it's a part of life.
                </FactDiv>
              </div>
              <div style={{ width: '75%' }}>
                <FactDiv>
                  Once damaged, even a properly repaired vehicle can lose up to 30% of its value. This loss of value is
                  called diminished value.
                </FactDiv>
              </div>

              <div style={{ width: '75%' }}>
                <FactDiv>
                  Diminished value occurs whether the accident is your fault or not.
                </FactDiv>
              </div>

              <div style={{ width: '75%' }}>
                <FactDiv>
                  Insurance will pay to repair your vehicle, but will not protect its value.
                </FactDiv>
              </div>

              <div style={{ width: '75%' }}>
                <FactDiv>
                  There are generally fewer buyers willing to purchase vehicles that were in accidents.
                </FactDiv>
              </div>

              <div style={{ width: '75%' }}>
                <FactDiv>
                  You're more likely to want to trade in your vehicle after it's been in an accident.
                </FactDiv>
              </div>
              <div style={{ width: '75%' }}>
                <Typography variant={'h4'} style={{ fontStyle: 'italic', paddingTop: 15 }}>
                  Think about it…would you want to buy a car if you knew it had been wrecked?
                </Typography>
              </div>
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <Typography variant={'h4'}>
              Loss of Value
            </Typography>
            <Typography>
              Unfortunately, once your vehicle has been in an accident it's worth less money - as much as 30% less -
              even
              if the accident wasn't your fault. This loss of value is called diminished value. And, while auto
              insurance
              will pay to repair your vehicle, auto insurance will not protect the value of your vehicle. This is why
              you
              need ValueProtect.
            </Typography>
            <br/>
            <Typography variant={'h4'}>
              Your Vehicle's "Report Card"
            </Typography>
            <Typography>
              With services like CARFAX® - that report on a vehicle's history - it's very easy for the next buyer to
              know
              whether your vehicle has been in an accident. This can make it difficult to find a buyer for your vehicle.
              It's a big problem that affects millions of people every year. This is why you need ValueProtect.
            </Typography>
            <br/>
            <Typography variant={'h4'}>
              Example of how ValueProtect works:
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    Trade-in value of your vehicle before an accident $18,000
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography style={{ marginTop: 10, fontWeight: 'bolder' }}>
              Trade-in value of your vehicle after an accident:
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography>
                      Without ValueProtect
                      $12,000
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>
                      With ValueProtect $18,000
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography style={{ marginTop: 10, textAlign: 'right' }}>
              (For illustrative purposes only. Individual experiences may vary.)
            </Typography>
            <br/>
            <Typography variant={'h4'}>
              Get peace of mind with ValueProtect
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <Typography style={{ marginLeft: 30 }}>
                  <ul>
                    <li>
                      ValueProtect costs just pennies a day.
                    </li>
                    <li>
                      ValueProtect works with your dealer to help increase the trade-in value of your vehicle by up to
                      $10,000.*
                    </li>
                    <li>
                      Finding a buyer for a previously wrecked vehicle can be difficult and inconvenient. But not if you
                      have
                      ValueProtect.
                    </li>
                    <li>
                      Ask your dealer about ValueProtect today. *Programs of $5,000 and $10,000 available.
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid item sm={12} md={6}>
                <img src={'/images/happy-couple.jpg'} alt={'happy people'}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

WhyValueProtect.propTypes = {};

export default WhyValueProtect;
