import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { MainPage } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: -200
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingLeft: '10%',
    paddingRight: '10%'
  },
  altRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingLeft: '10%',
    paddingRight: '10%',
    backgroundColor: '#d5dae2',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}));

const Home = (props) => {
  const classes = useStyles();

  return (
    <MainPage
      title={'DEFEAT DEPRECIATION!'}
      subTitle={'Even if fully repaired, vehicles involved in accidents suffer depreciation up to 30%!'}
      topImage={'/images/backgrounds/hp-hero-1920x600.jpg'}
      contentArray={[
        (
          <Grid container spacing={4}>
            <Grid item sm={12} md={6}>
              <Typography variant={'h4'}>What Is ValueProtect</Typography>
              <Typography variant={'body1'}>
                ValueProtect is a new and innovative, patent-pending F&I product designed to help protect customers from
                the
                negative financial effects of diminished value.
              </Typography>
              <br/>
              <Typography variant={'body1'}>
                ValueProtect is not insurance. It’s a simple and convenient way to get up to $5,000 or $10,000 more for
                your
                vehicle when you decide to trade it in – even if it has been in an accident. Please ask your dealer for
                more
                information about ValueProtect® today.
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <ReactPlayer controls={true} width={'100%'} style={{ maxWidth: 640 }}
                           url={'https://appcentral.blob.core.windows.net/website/valueprotect-video.mp4'}/>
            </Grid>
          </Grid>
        ),
        (
          <Grid container spacing={4}>
            <Grid sm={12} md={6} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
              <img src={'/images/car-vin-icon.png'} alt={'car vin icon'}/>
            </Grid>
            <Grid sm={12} md={6}>
              <Typography variant={'body1'}>
                Diminished value refers to the loss of value that occurs when a vehicle has been involved in an
                accident.
                Think about it…would you want to buy a car if you knew it had been wrecked? Of course you wouldn't and
                neither would most people. And that’s why even a properly repaired vehicle can lose up to 30% of its
                trade-in value.
              </Typography>
              <br/>
              <Typography variant={'body1'}>
                The use of VIN history services such as CARFAX® provide transparency into a vehicle’s history and often
                results in diminished value when there is evidence of a previous accident. And although auto insurance
                will
                pay to repair a vehicle, auto insurance will not protect the value of the vehicle. That’s where
                ValueProtect®
                steps in…
              </Typography>
            </Grid>
          </Grid>
        )
      ]}
    />
  );
};

Home.propTypes = {};

export default Home;
