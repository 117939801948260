import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Button, Hidden, IconButton, Menu, MenuItem, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(() => ({
    root: {
      boxShadow: 'none !important'
    },
    appBar: {},
    clearBar: {
      backgroundColor: 'transparent !important',
      boxShadow: 'none !important',
      transition: 'all 350ms ease 0s',
      color: '#fff'
    },
    bar: {
      color: '#FFFFFF',
      transition: 'all 350ms ease 0s',
      boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(5, 6, 6, 0.46)',
      backgroundColor: '#050606 !important'
    },
    appIcon: {
      flexGrow: 1
    }
  })
);

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const [fillAppBar, setFillAppBar] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  let history = useHistory();

  useEffect(() => {
    window.addEventListener('scroll', headerColorChange);
    return () => {
      window.removeEventListener('scroll', headerColorChange);
    };
  }, []);


  const headerColorChange = () => {
    // const { classes, color, changeColorOnScroll } = this.props;
    const windowsScrollTop = window.pageYOffset;
    setFillAppBar(windowsScrollTop <= 25);
  };

  const handleChange = (event) => {
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (link) => () => {
    setAnchorEl(null);
    if (link) {
      history.push(link);
    }
  };

  return (
    <AppBar
      {...rest}
      className={clsx({
        [classes.root]: true,
        [classes.bar]: true,
        [classes.bar]: !fillAppBar,
        [classes.clearBar]: fillAppBar
      })}
      position="fixed"
    >
      <Toolbar>
        <div className={classes.appIcon}>
          <RouterLink to="/">
            <img
              alt="Logo"
              style={{ transition: 'all 350ms ease 0s' }}
              width={300}
              src={'/images/logos/logo-white.png'}
            />
          </RouterLink>
        </div>
        <Hidden mdDown>
          <RouterLink to={'/why-value-protect'}>
            <Button style={{ color: 'white' }}>Why ValueProtect</Button>
          </RouterLink>
          <Button color="inherit" onClick={handleMenu}>Redeem</Button>
          <Menu
            style={{ marginTop: 50, marginLeft: 50 }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            open={open}
            onClose={handleClose()}
          >
            <MenuItem onClick={handleClose('customer-instructions')}>Customer Instructions</MenuItem>
            <MenuItem onClick={handleClose('dealer-instructions')}>Dealer Instructions</MenuItem>
            <MenuItem onClick={handleClose('redeem-value-protect')}>Redeem ValueProtect</MenuItem>
          </Menu>
          <div style={{ width: 100 }}/>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon/>
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
