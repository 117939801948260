import { colors } from '@material-ui/core';

const tinycolor = require('tinycolor2');
const white = '#FFFFFF';
const black = '#000000';
const primaryColor = '#191B1B';
const secondaryColor = '#3C4858';
const tertiaryColor = '#ecab3f';
const warningColor = '#ffa726';
const dangerColor = '#ef5350';
const successColor = '#66bb6a';
const infoColor = '#26c6da';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: tinycolor(primaryColor).darken(8).toHexString(),
    main: primaryColor,
    light: tinycolor(primaryColor).lighten(8).toHexString()
  },
  secondary: {
    contrastText: white,
    dark: tinycolor(secondaryColor).darken(8).toHexString(),
    main: secondaryColor,
    light: tinycolor(secondaryColor).lighten(8).toHexString()
  },
  tertiary: {
    contrastText: white,
    dark: tinycolor(tertiaryColor).darken(8).toHexString(),
    main: tertiaryColor,
    light: tinycolor(tertiaryColor).lighten(8).toHexString()
  },
  success: {
    contrastText: white,
    dark: tinycolor(successColor).darken(8).toHexString(),
    main: successColor,
    light: tinycolor(successColor).lighten(8).toHexString()
  },
  info: {
    contrastText: white,
    dark: tinycolor(infoColor).darken(8).toHexString(),
    main: infoColor,
    light: tinycolor(infoColor).lighten(8).toHexString()
  },
  warning: {
    contrastText: white,
    dark: tinycolor(warningColor).darken(8).toHexString(),
    main: warningColor,
    light: tinycolor(warningColor).lighten(8).toHexString()
  },
  error: {
    contrastText: white,
    dark: tinycolor(dangerColor).darken(8).toHexString(),
    main: dangerColor,
    light: tinycolor(dangerColor).lighten(8).toHexString()
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
};
