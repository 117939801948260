import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Minimal as MinimalLayout } from './layouts';
import {
  CustomerInstructions as CustomerInstructionsView,
  DealerInstructions as DealerInstructionsView,
  Home as HomeView,
  Login as LoginView,
  NotFoud as NotFoundView,
  RedeemValueProtect as RedeemValueProtectView,
  WhyValueProtect as WhyValueProtectView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/home"
      />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MinimalLayout}
        path="/home"
      />
      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      <RouteWithLayout
        component={WhyValueProtectView}
        exact
        layout={MinimalLayout}
        path="/why-value-protect"
      />
      <RouteWithLayout
        component={CustomerInstructionsView}
        exact
        layout={MinimalLayout}
        path="/customer-instructions"
      />
      <RouteWithLayout
        component={DealerInstructionsView}
        exact
        layout={MinimalLayout}
        path="/dealer-instructions"
      />
      <RouteWithLayout
        component={RedeemValueProtectView}
        exact
        layout={MinimalLayout}
        path="/redeem-value-protect"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found"/>
    </Switch>
  );
};

export default Routes;
