import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { Background, Parallax } from 'react-parallax';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingLeft: '10%',
    paddingRight: '10%'
  }
}));

const DealerInstructions = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Parallax
        bgImageAlt="the dog"
        strength={300}
      >
        <div style={{ height: 500 }}>
          <div style={{
            display: 'flex',
            flex: 1,
            height: '100%',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '10%',
            paddingRight: '10%'
          }}>
            <Typography variant={'h3'} style={{ color: '#fff', fontWeight: 'bold' }}>
              Customer Instructions
            </Typography>
            <Typography variant={'h4'} style={{ color: '#fff', fontWeight: 'bold' }}>
              Please refer to your ValueProtect agreement for detailed instructions.
            </Typography>
          </div>
        </div>
        <Background>
          <img src={'/images/backgrounds/contact-dealer-instructions-1920x600.jpg'} height={800}
               alt={'home background'}/>
        </Background>
      </Parallax>
      <div className={classes.content}>
        <Grid container spacing={4}>
          <Grid item sm={12} md={6}>
            <Typography variant={'h4'}>
              Dealer Redemption Process
            </Typography>
            <Typography>
              <ol type="1" style={{ marginLeft: 30 }}>
                <li>
                  Appraise the vehicle and establish Dealer ACV.
                </li>
                <li>
                  Establish that the vehicle was involved in a reported accident:
                  <ol type="A" style={{ marginLeft: 30 }}>
                    <li>The accident appears on the CARFAX® (or other VIN history) report OR</li>
                    <li>
                      The customer provided a copy of the insurance claim. This can typically be obtained online or by
                      calling the
                      insurance carrier’s toll free phone number OR
                    </li>
                    <li>
                      The customer provided a copy of the police report
                    </li>
                  </ol>
                </li>
                <li>
                  Establish the repair amount.
                  <ol type="A" style={{ marginLeft: 30 }}>
                    <li>Customer provided a copy of the insurance claim OR</li>
                    <li>Customer provided a copy of the body shop invoice</li>
                  </ol>
                </li>
                <li>Visit <a
                  href={'https://valueprotect.nationsafedrivers.com/redeem-value-protect'}>valueprotect.nationsafedrivers.com/redeem-value-protect</a>
                </li>
                <li>Click on “CALCULATE OFFER”.</li>
                <li>Follow the simple instructions and enter the required information</li>
                <li>This will display the ValueProtect offer. Print the ValueProtect offer.</li>
                <li>If customer accepts the offer, the dealer advances the ValueProtect amount and is reimbursed by the
                  Program Administrator (NSD Administrators)
                </li>
              </ol>
            </Typography>

          </Grid>
          <Grid item sm={12} md={6}>
            <Typography variant={'h4'}>
              Reimbursement Instructions
            </Typography>
            <Typography>
              <ol type="1" style={{ marginLeft: 30 }}>
                <li>
                  Submit the required documentation listed below to the Program Administrator:
                  <ol type="A" style={{ marginLeft: 30 }}>
                    <li>
                      Proof of a reported accident from #2 under Dealer Redemption Process.
                      <ol type="i" style={{ marginLeft: 30 }}>
                        <li>Copy of the CARFAX® (or other VIN history) report OR</li>
                        <li>Copy of the insurance claim OR</li>
                        <li>Copy of the police report.</li>
                      </ol>
                    </li>
                    <li>Copy of the ValueProtect offer.</li>
                    <li>
                      Copy of the buyer’s order for the new vehicle OR if the customer did not purchase/lease another
                      vehicle, a copy of the signed odometer statement and proof of title reassignment.
                    </li>
                  </ol>
                </li>
                <li>
                  Payment will be received within 7 business days.
                </li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

DealerInstructions.propTypes = {};

export default DealerInstructions;
