import React from 'react';
import { MainPage } from '../../components';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import validate from 'validate.js';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Check, CloudUpload as UploadIcon } from '@material-ui/icons';
import each from 'lodash/each';
import filter from 'lodash/filter';
import moment from 'moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import NumberFormat from 'react-number-format';
import { PurchaseOffer } from './components';
import nsdApiKey from '../../utils/nsdApiKey';

const contractAPIUrl = `${process.env.REACT_APP_NSD_DEALER_API_BASE_URL}/contract`;
const vinAPIUrl = `${process.env.REACT_APP_NSD_VIN_DECODE_BASE_URL}/decode`;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip);

moment.locale('en');
const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    transition: 'all 350ms ease 0s',
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  active: {
    '& $line': {
      width: '100%',
      transition: 'all 350ms ease 0s',
      borderColor: theme.palette.success.dark
    }
  },
  completed: {
    '& $line': {
      width: '100%',
      transition: 'all 350ms ease 0s',
      borderColor: theme.palette.success.dark
    }
  },
  line: {
    width: '0%',
    borderColor: theme.palette.secondary.light,
    transition: 'all 350ms ease 0s',
    borderTopWidth: 3,
    borderRadius: 1
  }
}))(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  active: {
    color: theme.palette.info.dark
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  completed: {
    color: theme.palette.success.dark,
    zIndex: 1,
    fontSize: 18
  }
}));

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed ? <Check className={classes.completed}/> : <div className={classes.circle}/>}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool
};

function getSteps() {
  return ['Enter contract number, VIN number, and mileage', 'Validate Information', 'Confirm vehicle history', 'Enter Dealer ACV and trade in', 'Submit your information'];
}

const DangerButton = withStyles((theme) => ({
  root: {
    color: theme.palette.error.contrastText,
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    backgroundColor: theme.palette.error.main,
    borderColor: theme.palette.error.dark,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      boxShadow: '0 4px 4px 0 rgba(0,0,0,0.074), 0 2px 1px -1px rgba(0,0,0,0.12), 0 4px 6px 0 rgba(0,0,0,0.10)'
    },
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  }
}))(Button);

const useStylesTwo = makeStyles(theme => ({
  root: {},
  form: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  formTwo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  formField: {
    margin: theme.spacing(4)
  },
  fileInput: {
    display: 'none'
  },
  buttonError: {
    borderColor: theme.palette.error.main
  }
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      decimalScale={2}
      fixedDecimalScale
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.floatValue,
            name: name
          }
        });
      }}
      prefix="$"
      thousandSeparator
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

validate.validators.validateVinNumber = function(value) {
  return new validate.Promise(function(resolve, reject) {
    if (!value) {
      resolve();
    } else if (value.length >= 17) {
      fetch(`${vinAPIUrl}?vin=${value}`, {
        method: 'GET',
        headers: { ...nsdApiKey }
      })
        .then((res, othere) => {
          return res.json();
        })
        .then(
          (result) => {
            resolve();
          },
          (error, other) => {
            resolve('is invalid');
          }
        )
        .catch((error) => {
          resolve('is invalid');
        });
    } else {
      resolve('is invalid');
    }
  });
};

validate.validators.validateContractNumber = function(value) {
  console.log('validateContractNumber: value', value);
  return new validate.Promise(function(resolve, reject) {
    if (!value) {
      resolve();
    } else {
      console.log('validateContractNumber fetch');
      fetch(`${contractAPIUrl}?memberNumber=${value}`, {
        method: 'GET',
        headers: { ...nsdApiKey }
      })
        .then((res, othere) => {
          return res.json();
        })
        .then(
          (result) => {
            console.log('validateContractNumber: result', result);
            console.log(filter(result.contract, { covName: 'VALUE PROTECT BENEFIT' }).length);
            if (filter(result.contract, { covName: 'VALUE PROTECT BENEFIT' }).length >= 1) {
              resolve();
            } else {
              resolve('^A value protect benefit could not be located for contract number ' + value);
            }
          },
          (error, other) => {
            console.log('validateContractNumber: error', error);
            resolve('is invalid');
          }
        )
        .catch((error) => {
          console.log('validateContractNumber: error', error);
          resolve('is invalid');
        });
    }
  });
};

//Average trade-in value from KBB, NADA, or Black Book.<br/>(Amount to be verified by Administrator.)
const RedeemValueProtect = (props) => {
    const classes = useStylesTwo();
    const [value, setValue] = React.useState();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const fileInputRef = React.useRef(null);
    const [locale, setLocale] = React.useState('en');
    const [vehicleDataFetchError, setVehicleDataFetchError] = React.useState(null);
    const [isMovingToNext, setIsMovingToNext] = React.useState(false);

    const [formState, setFormState] = React.useState({
      isValid: false,
      values: {
        dealershipName: 'ABC Nissan',
        dealershipCode: 'DLC1243234'
      },
      formEdited: false,
      touched: {},
      errors: {},
      defaultValues: {}
    });

    const getConstraints = () => {
      let constraints = {};
      if (activeStep === 0) {
        constraints = {
          'contractNumber': {
            presence: true,
            validateContractNumber: true
          },
          'vinNumber': {
            validateVinNumber: true,
            presence: true
          },
          'mileage': {
            presence: true
          }
        };
      }
      if (activeStep === 2 && formState.values.reportedAccident === 'yes') {
        constraints = {
          'claimCost': {
            presence: true
          },
          'reportedAccident': {
            presence: true
          },
          'accidentDate': {
            presence: true
          }
        };
      }
      if (activeStep === 2 && formState.values.reportedAccident !== 'yes') {
        constraints = {
          'reportedAccident': {
            presence: true
          }
        };
      }
      if (activeStep === 3) {
        constraints = {
          'acv': {
            presence: true
          },
          'tradeIn': {
            presence: true
          }
        };
      }
      if (activeStep === 4) {
        constraints = {
          'submittedBy': {
            presence: true
          },
          'title': {
            presence: true
          }
        };
      }
      return constraints;
    };

    const validateFormData = (moveToNext, touchAll, removeUntouched) => {
      setIsMovingToNext(true);
      let constraints = getConstraints();
      let newTouched = {
        ...formState.touched
      };
      if (touchAll) {
        let touched = {};
        each(Object.keys(constraints), key => {
          touched[key] = true;
        });
        newTouched = {
          ...formState.touched,
          ...touched
        };
      }
      if (removeUntouched) {
        let newConstraints = {};
        each(Object.keys(constraints), key => {
          if (formState.touched[key])
            newConstraints[key] = constraints[key];
        });
        constraints = newConstraints;
      }

      validate.async(formState.values, constraints)
        .then((data) => {
          setFormState(formState => ({
            ...formState,
            formEdited: true,
            isValid: true,
            errors: {},
            touched: newTouched
          }));

          setIsMovingToNext(false);
          if (moveToNext) {
            if (activeStep === 0) {
              fetchVinAndContractData();
            }
            if (activeStep < 5) {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
          }
        }, (errors) => {
          setFormState(formState => ({
            ...formState,
            formEdited: true,
            isValid: false,
            errors: errors,
            touched: newTouched
          }));
          setIsMovingToNext(false);
        });

    };

    React.useEffect(() => {
      if (formState.formEdited) {
        validateFormData(false, false, true);
      }
    }, [formState.values]);

    const handleNext = () => {
      validateFormData(true, true);
    };

    const handleBack = () => {
      if (activeStep > 0) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    };

    const handleReset = () => {
      setFormState({
        isValid: false,
        values: {},
        formEdited: false,
        touched: {},
        errors: {},
        defaultValues: {}
      });
      setActiveStep(0);
    };

    const handleChange = (event) => {
      if (event && event.persist) {
        event.persist();
      }
      if (!event.target) {
        return;
      }
      setFormState(formState => ({
        ...formState,
        formEdited: true,
        values: {
          ...formState.values,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true
        }
      }));
    };

    const openFileDialog = () => {
      fileInputRef.current.click();
    };

    const handleOnFilesAdded = (evt) => {
      const files = evt.target.files;
      handleChange({ target: { name: 'shopInvoice', value: files[0] } });
    };

    const hasError = field => {
      return !!((formState.touched[field]) && formState.errors[field]);
    };

    const handleDateChange = date => {
      if (date.isValid()) {
        let newDay = date.get('date');
        let newMonth = date.get('month');
        let newYear = date.get('year');
        if (formState.values['accidentDate']) {
          let valueToChange = moment(formState.values['accidentDate']);
          valueToChange.set('date', newDay);
          valueToChange.set('month', newMonth);
          valueToChange.set('year', newYear);
          handleChange({ target: { name: 'accidentDate', value: valueToChange } });
        } else {
          handleChange({ target: { name: 'accidentDate', value: date } });
        }
      }
    };

    const fetchVinAndContractData = () => {
      fetch(`${contractAPIUrl}?vin=${formState.values.vinNumber}&memberNumber=${formState.values.contractNumber}`, {
        method: 'GET',
        headers: { ...nsdApiKey }
      })
        .then((res, othere) => {
          return res.json();
        })
        .then(
          (result) => {
            setFormState(formState => ({
              ...formState,
              values: {
                ...formState.values,
                contract: filter(result.contract, { covName: 'VALUE PROTECT BENEFIT' })[0],
                vehicleData: result.vehicle.specification
              }
            }));
          },
          (error, other) => {
            setVehicleDataFetchError('Unable to collect vehicle data');
            // console.log('error', error);
            // console.log('other', other);
          }
        )
        .catch((error) => {
          setVehicleDataFetchError('Unable to collect vehicle data');
          // console.log(error);
        });
    };


    return (

      <MainPage
        contentArray={[
          (
            <div style={{ width: '100%' }}>
              {activeStep < 5 && <div>
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  connector={<QontoConnector/>}
                  style={{ backgroundColor: 'transparent' }}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>}
              {activeStep === 0 &&
              <div className={classes.form}>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    md={4}
                    sm={12}
                  >
                    <TextField
                      className={classes.formField}
                      error={hasError('contractNumber')}
                      fullWidth
                      helperText={
                        hasError('contractNumber') ? formState.errors['contractNumber'][0] : null
                      }
                      label={'Contract Number'}
                      name={'contractNumber'}
                      onChange={handleChange}
                      value={formState.values['contractNumber'] || null}
                      variant={'outlined'}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={12}
                  >
                    <TextField
                      className={classes.formField}
                      error={hasError('vinNumber')}
                      fullWidth
                      helperText={
                        hasError('vinNumber') ? formState.errors['vinNumber'][0] : null
                      }
                      label={'VIN Number'}
                      name={'vinNumber'}
                      onChange={handleChange}
                      value={formState.values['vinNumber'] || null}
                      variant={'outlined'}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={12}
                  >
                    <TextField
                      className={classes.formField}
                      error={hasError('mileage')}
                      fullWidth
                      helperText={
                        hasError('mileage') ? formState.errors['mileage'][0] : null
                      }
                      label={'Current Mileage'}
                      name={'mileage'}
                      onChange={handleChange}
                      value={formState.values['mileage'] || null}
                      variant={'outlined'}
                    />
                  </Grid>
                </Grid>
              </div>
              }
              {activeStep === 1 &&
              <div className={classes.form}>
                {
                  !formState.values.vehicleData ?
                    <div>
                      {
                        !vehicleDataFetchError ?
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <div>
                              <CircularProgress/>
                            </div>
                            <div>
                              <Typography variant={'subtitle1'}>Collecting Vehicle Data</Typography>
                            </div>
                          </div>
                          :
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <Typography>{vehicleDataFetchError}</Typography>
                          </div>
                      }
                    </div>
                    :
                    <Grid
                      container
                      spacing={4}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <Card>
                          <CardContent>
                            <Typography variant={'h4'}>Vehicle Information</Typography>
                            <Table size={'small'}>
                              <TableHead>
                                <TableRow>
                                  <TableCell>VIN</TableCell>
                                  <TableCell>Year</TableCell>
                                  <TableCell>Make</TableCell>
                                  <TableCell>Model</TableCell>
                                  <TableCell>Mileage</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>{formState.values.vehicleData.vin}</TableCell>
                                  <TableCell>{formState.values.vehicleData.year}</TableCell>
                                  <TableCell>{formState.values.vehicleData.make}</TableCell>
                                  <TableCell>{formState.values.vehicleData.model}</TableCell>
                                  <TableCell>{formState.values.mileage}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <Card>
                          <CardContent>
                            <Typography variant={'h4'}>Contract Information</Typography>
                            <Table size={'small'}>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Owned by</TableCell>
                                  <TableCell>Term</TableCell>
                                  <TableCell>Maximum Benefit</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>{formState.values.contract.polFname} {formState.values.contract.polLname}</TableCell>
                                  <TableCell>{formState.values.contract.termMonths} Months</TableCell>
                                  <TableCell>{formatter.format(formState.values.contract.covLimit)}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                }
              </div>
              }
              {activeStep === 2 &&
              <div className={classes.formTwo}>
                <div>
                  <FormControl
                    component="fieldset"
                    error={hasError('reportedAccident')}
                  >
                    <FormLabel component="legend">Was the vehicle in a reported accident that appears on the CARFAX (or
                      other VIN history) report, or did the customer provide a copy of EITHER the police report OR the
                      insurance claim?</FormLabel>
                    <RadioGroup
                      aria-label="reportedAccident"
                      error={hasError('reportedAccident')}
                      helperText={
                        hasError('reportedAccident') ? formState.errors['reportedAccident'][0] : null
                      }
                      name={'reportedAccident'}
                      onChange={handleChange}
                      value={formState.values['reportedAccident'] || null}
                    >
                      <FormControlLabel
                        control={<Radio/>}
                        label="Yes"
                        value="yes"
                      />
                      <FormControlLabel
                        control={<Radio/>}
                        label="No"
                        value="no"
                      />
                    </RadioGroup>
                    <FormHelperText>{
                      hasError('reportedAccident') ? formState.errors['reportedAccident'][0] : null
                    }</FormHelperText>
                  </FormControl>
                </div>
                {formState.values['reportedAccident'] === 'yes' &&
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    height: formState.values['reportedAccident'] === 'yes' ? '100%' : 0,
                    transition: 'all 350ms ease 0s'
                  }}
                >
                  <Grid
                    container
                    spacing={4}
                  >
                    <Grid
                      item
                      md={4}
                      sm={12}
                    >
                      <div>
                        <MuiPickersUtilsProvider
                          libInstance={moment}
                          locale={locale}
                          utils={MomentUtils}
                        >
                          <KeyboardDatePicker
                            className={classes.formField}
                            error={hasError('accidentDate')}
                            format="MM/DD/YYYY"
                            fullWidth
                            helperText={
                              hasError('accidentDate') ? formState.errors['accidentDate'][0] : null
                            }
                            id="date-picker-inline"
                            inputVariant="outlined"
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                            label={'Date of the Accident'}
                            margin="normal"
                            name={'accidentDate'}
                            onChange={handleDateChange}
                            value={formState.values['accidentDate'] || null}
                            value={formState.values['accidentDate']}
                            variant="inline"
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      sm={12}
                    >
                      <div>
                        <TextField
                          className={classes.formField}
                          error={hasError('claimCost')}
                          fullWidth
                          helperText={
                            hasError('claimCost') ? formState.errors['claimCost'][0] : null
                          }
                          InputProps={{
                            inputComponent: NumberFormatCustom
                          }}
                          label={'Claim Amount'}
                          name={'claimCost'}
                          onChange={handleChange}
                          type="text"
                          value={formState.values['claimCost'] || null}
                          variant="outlined"
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      sm={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                        padding: 10
                      }}
                    >
                      <Button
                        className={clsx({ [classes.buttonError]: hasError('shopInvoice') })}
                        onClick={openFileDialog}
                        startIcon={<UploadIcon/>}
                        style={{ marginLeft: 20 }}
                        variant="outlined"
                      >
                        Upload Claim Detail
                      </Button>
                      <input
                        className={classes.fileInput}
                        onChange={handleOnFilesAdded}
                        ref={fileInputRef}
                        type="file"
                      />
                    </Grid>
                  </Grid>
                </div>}
              </div>
              }
              {activeStep === 3 &&
              <div className={classes.form}>
                <Grid
                  container
                  justify={'center'}
                  spacing={2}
                >
                  <Grid
                    item
                    md={4}
                    sm={12}
                  >
                    <TextField
                      className={classes.formField}
                      error={hasError('acv')}
                      fullWidth
                      helperText={
                        hasError('acv') ? formState.errors['acv'][0] : null
                      }
                      InputProps={{
                        inputComponent: NumberFormatCustom
                      }}
                      label={'Dealer ACV'}
                      name={'acv'}
                      onChange={handleChange}
                      type="text"
                      value={formState.values['acv'] || null}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={12}
                  >
                    <TextField
                      className={classes.formField}
                      error={hasError('tradeIn')}
                      fullWidth
                      helperText={
                        hasError('tradeIn') ? formState.errors['tradeIn'][0] : (
                          <React.Fragment>
                            Average Trade-In from Black Book, KBB, or NADA.
                            (Amount to be verified by Administrator).
                            Please do NOT include adds or deducts.
                          </React.Fragment>)
                      }
                      InputProps={{
                        inputComponent: NumberFormatCustom
                      }}
                      label={'Average Trade-In'}
                      name={'tradeIn'}
                      onChange={handleChange}
                      type="text"
                      value={formState.values['tradeIn'] || null}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </div>
              }
              {activeStep === 4 &&
              <div className={classes.form}>
                <Grid
                  container
                  justify={'center'}
                  spacing={2}
                >
                  <Grid
                    item
                    md={4}
                    sm={12}
                  >
                    <TextField
                      className={classes.formField}
                      error={hasError('submittedBy')}
                      fullWidth
                      helperText={
                        hasError('submittedBy') ? formState.errors['submittedBy'][0] : null
                      }
                      label={'Submitted by'}
                      name={'submittedBy'}
                      onChange={handleChange}
                      value={formState.values['submittedBy'] || null}
                      variant={'outlined'}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={12}
                  >
                    <TextField
                      className={classes.formField}
                      error={hasError('title')}
                      fullWidth
                      helperText={
                        hasError('title') ? formState.errors['title'][0] : null
                      }
                      label={'Title'}
                      name={'title'}
                      onChange={handleChange}
                      value={formState.values['title'] || null}
                      variant={'outlined'}
                    />
                  </Grid>
                </Grid>
              </div>
              }

              {activeStep === 5 &&
              <PurchaseOffer formData={formState.values}/>
              }

              {activeStep < 5 &&
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 15 }}>
                <DangerButton
                  onClick={handleReset}
                  style={{ marginRight: 10 }}
                  variant={'outlined'}
                >Cancel</DangerButton>
                <Button
                  color="secondary"
                  onClick={handleBack}
                  style={{ marginRight: 10 }}
                  variant={'outlined'}
                >Back</Button>
                <Button
                  disabled={isMovingToNext}
                  color="primary"
                  onClick={handleNext}
                  style={{ marginRight: 10 }}
                  variant={'contained'}
                >Next</Button>
              </div>
              }
            </div>
          )
        ]}
        fullWidth={activeStep >= 5}
        subTitle={''}
        title={''}
        topImage={'/images/backgrounds/AdobeStock_212221095.jpeg'}
      />
    );
  }
;

RedeemValueProtect.propTypes = {};

export default RedeemValueProtect;
