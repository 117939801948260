import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer';
import palette from '../../../../theme/palette';
import { Print as PrintIcon } from '@material-ui/icons';
import nsdApiKey from '../../../../utils/nsdApiKey';
import orderBy from 'lodash/orderBy';
import first from 'lodash/first';

const tinycolor = require('tinycolor2');

const styles = StyleSheet.create({
  body: {
    paddingTop: 45,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 24,
    textAlign: 'center'
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    textDecoration: 'underline'
  },
  text: {
    margin: 12,
    fontSize: 11,
    textAlign: 'justify',
    flexWrap: 'wrap'
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: tinycolor('#000').lighten(75).toHexString()
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  headerText: {
    fontSize: 24,
    marginVertical: 10,
    textDecoration: 'underline'
  },
  headerImage: {
    marginHorizontal: 400,
    marginVertical: -85,
    width: 150
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center'
  },
  firstRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    alignContent: 'center',
    marginVertical: 120,
    borderBottomColor: palette.tertiary.main,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    paddingBottom: 15
    // paddingTop: 5
  },
  secondRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    alignContent: 'center',
    marginVertical: 120,
    borderBottomColor: palette.tertiary.main,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    paddingBottom: 15
    // paddingTop: 5
  },
  thirdRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    alignContent: 'center',
    marginVertical: 120,
    borderBottomColor: palette.tertiary.main,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    paddingBottom: 15,
    paddingTop: 10
  },
  primaryFirstRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    alignContent: 'center',
    marginVertical: 125,
    borderBottomColor: palette.tertiary.main,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    paddingBottom: 15,
    paddingTop: 5
  },
  textRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    marginVertical: 75,
    borderBottomColor: palette.tertiary.main,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    paddingBottom: 15,
    paddingTop: 5
  },
  textRowTwo: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    marginVertical: 20,
    paddingBottom: 15,
    paddingTop: 5
  },
  textCol: {
    flexDirection: 'col',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    marginVertical: -150,
    height: 350
  },
  textColTwo: {
    flexDirection: 'col',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    borderTopColor: palette.tertiary.main,
    borderTopWidth: 2,
    borderTopStyle: 'solid',
    marginVertical: 0,
    marginHorizontal: 0,
    height: 350
  },
  centerTextRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    alignContent: 'center',
    marginVertical: -120,
    borderBottomColor: palette.tertiary.main,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    padding: 15
  },
  standardRow: {
    borderBottomColor: palette.tertiary.main,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid'
  },
  label: {
    fontSize: 13,
    letterSpacing: 1,
    fontWeight: 'bold !important'
  },
  leftText: {
    fontSize: 12,
    textAlign: 'left',
    width: 280,
    paddingRight: 10
  },
  leftTextTwo: {
    fontSize: 12,
    marginHorizontal: 25,
    paddingBottom: 2
  },
  centerText: {
    fontSize: 12,
    paddingTop: 2,
    paddingBottom: 12,
    marginHorizontal: 35,
    textAlign: 'center'
  },
  leftTextThree: {
    fontSize: 12,
    marginHorizontal: 45,
    paddingBottom: 2
  },
  leftTextFour: {
    fontSize: 12,
    marginHorizontal: 55,
    paddingBottom: 2
  },
  leftTextFive: {
    fontSize: 12,
    textAlign: 'left',
    width: 280,
    paddingRight: 10
  },
  leftTextSix: {
    fontSize: 12,
    textAlign: 'left',
    width: 270,
    paddingRight: 10
  },
  leftTextSixTwo: {
    fontSize: 12,
    textAlign: 'left',
    width: 180
  },
  sectionTitle: {
    marginVertical: -120,
    marginHorizontal: 25
  },
  sectionTitleOne: {
    marginVertical: -120,
    marginHorizontal: 25
  },
  sectionTitleCont: {
    marginVertical: 120,
    marginHorizontal: 25
  },
  textPadding: {
    paddingTop: 2
  }
});

// Create Document Component
const MyDocument = (props) => {

  console.log('MyDocument', props);
  const { formData, offer } = props;
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.row}>
          <Text style={styles.headerText}>
            Purchase Offer
          </Text>
        </View>
        <Image style={styles.headerImage} src={'/images/logos/logo.png'}/>
        <View style={styles.thirdRow}>
          <View style={styles.leftTextSix}>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Contract Number: </Text>
              <Text style={styles.text}>{formData.contract.polMember}</Text>
            </Text>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Contract Date: </Text> <Text
              style={styles.text}>{moment(formData.contract.effDt).format('L')}</Text>
            </Text>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Year, Make, Model, VIN#: </Text>
              <Text style={styles.text}>
                {formData.vehicleData.year}, {formData.vehicleData.make}, {formData.vehicleData.model},
                {formData.vehicleData.vin}
              </Text>
            </Text>
          </View>
          <View style={styles.leftTextSixTwo}>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Offer ID: </Text> <Text style={styles.text}>{offer.contractId}</Text>
            </Text>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Offer Date: </Text> <Text style={styles.text}>{offer.contractStartDate}</Text>
            </Text>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Valid Until: </Text> <Text
              style={styles.text}>{offer.contractDataExpirationDate}</Text>
            </Text>
          </View>
        </View>
        <View style={styles.sectionTitleOne}>
          <Text style={styles.subtitle}>
            CUSTOMER
          </Text>
        </View>
        <View style={styles.secondRow}>
          <View style={styles.leftTextFive}>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Name: </Text> <Text
              style={styles.text}>{formData.contract.polFname} {formData.contract.polLname}</Text>
            </Text>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Address: </Text>
              <Text style={styles.text}>
                {formData.contract.polAddress} {formData.contract.polAddress2 ? formData.contract.polAddress2 : ''}
                {formData.contract.polCity}, {formData.contract.polState} {formData.contract.polZip}
              </Text>
            </Text>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Phone Number: </Text> <Text
              style={styles.text}>{formatPhoneNumber(formData.contract.polPhone)}</Text>
            </Text>
          </View>
          <View>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Dealership Name: </Text>
              <Text style={styles.text}>ABC Nissan Inc.</Text>
            </Text>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Dealer Code: </Text>
              <Text style={styles.text}>VP12345</Text>
            </Text>
          </View>
        </View>
        <View style={styles.sectionTitle}>
          <Text style={styles.subtitle}>
            OFFER DETAILS
          </Text>
        </View>
        <View style={styles.firstRow}>
          <View style={styles.leftTextFive}>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Reported Accident: </Text> <Text
              style={styles.text}>{formData.reportedAccident.toUpperCase()}</Text>
            </Text>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Accident Date: </Text> <Text
              style={styles.text}>{formData.reportedAccident === 'yes' ? moment(formData.accidentDate).format('L') : ''}</Text>
            </Text>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Repair Amount: </Text> <Text
              style={styles.text}>{formData.reportedAccident === 'yes' ? moneyFormatter.format(formData.claimCost) : ''}</Text>
            </Text>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Claim Detail: </Text> <Text
              style={styles.text}>{formData.reportedAccident === 'yes' ? formData.shopInvoice?.name : ''}</Text>
            </Text>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Mileage: </Text> <Text
              style={styles.text}>{numberFormatter.format(formData.mileage)}</Text>
            </Text>
          </View>
          <View>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Dealer’s Offer: </Text> <Text
              style={styles.text}>{moneyFormatter.format(formData.acv)}</Text>
            </Text>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>Average Trade-In: </Text> <Text
              style={styles.text}>{moneyFormatter.format(formData.tradeIn)}</Text>
            </Text>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>ValueProtect® Amount: </Text> <Text
              style={styles.text}>{moneyFormatter.format(offer.vpAmount)}</Text>
            </Text>
            <Text style={styles.textPadding}>
              <Text style={styles.label}>ValueProtect® Offer: </Text> <Text
              style={styles.text}>{moneyFormatter.format(offer.vpOffer)}</Text>
            </Text>
          </View>
        </View>
        <View style={styles.centerTextRow}>
          <Text style={{ textAlign: 'center', fontSize: 12 }}>
            I verify that the information presented is accurate to the best of my knowledge. The required
            documentation will be forwarded to the Program Administrator according to the instructions below. Failure
            to accurately state the condition or value of the vehicle or to remit the required documentation may
            result in the ValueProtect® offer being rescinded. Submitted
            by {formData.dealershipName} / {formData.submittedBy} / {formData.title}
          </Text>
        </View>
        <View style={styles.sectionTitleCont}>
          <Text style={styles.subtitle}>
            DEALER REIMBURSEMENT INSTRUCTIONS
          </Text>
        </View>
        <View style={styles.textRowTwo}>
          <View style={styles.textCol}>
            <Text style={styles.leftTextTwo}>
              Please submit the required documentation listed below to the Program Administrator:
            </Text>
            <Text style={styles.leftTextThree}>
              A. Proof of the reported accident:
            </Text>
            <Text style={styles.leftTextFour}>
              1. Copy of the CARFAX® (or other VIN history) report OR
            </Text>
            <Text style={styles.leftTextFour}>
              2. Copy of the insurance claim OR
            </Text>
            <Text style={styles.leftTextFour}>
              3. Copy of the police report.
            </Text>
            <Text style={styles.leftTextThree}>
              B. Proof of repair amount:
            </Text>
            <Text style={styles.leftTextFour}>
              1. Copy of the insurance claim OR
            </Text>
            <Text style={styles.leftTextFour}>
              2. Copy of the body shop invoice.
            </Text>
            <Text style={styles.leftTextThree}>
              C. Print out of the Average Trade-in value from KBB, NADA, or Black Book. (Amount to be verified by
              Administrator).
            </Text>
            <Text style={styles.leftTextThree}>
              D. Copy of the ValueProtect® Purchase Offer (this document - please retain a copy for your records)
            </Text>
            <Text style={styles.leftTextThree}>
              E. Copy of the signed buyer’s order for the replacement vehicle OR if the customer did not purchase
              another
              vehicle, a copy of the signed odometer statement and proof of title reassignment. Payment will be received
              within 7 days.
            </Text>
          </View>
        </View>
        <View style={styles.textColTwo}>
          <Text style={styles.subtitle}>
            ASSIGNMENT OF CONTRACT
          </Text>
          <Text style={styles.centerText}>
            FOR VALUE RECEIVED, Value Guard, LLC (as the undersigned Assignor) hereby assigns, transfers and sets over
            all of its rights, title, and interest in and to the Agreement (ValueProtect® Contract #) to the undersigned
            Assignee, who assumes this Agreement and agrees to purchase or accept as a trade in the Optioned Vehicle on
            behalf of Assignor.
          </Text>
          <Text style={styles.centerText}>
            Signed this _________ day of ____________________, 20_____.
          </Text>
          <Text style={styles.centerText}>
            Assignor: ________________________________________
          </Text>
          <Text style={styles.centerText}>
            Assignee (Dealer): ___________________________________________
          </Text>
          <Text style={styles.centerText}>
            Program Administrator:
          </Text>
          <Text style={styles.centerText}>
            NSD Administrators 800 West Yamato Road, Suite 100 • Boca Raton, FL 33431
          </Text>
          <Text style={styles.centerText}>
            Phone: 1-888-684-9327 • vprotect@nationsafedrivers.com
          </Text>
        </View>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed/>
      </Page>
    </Document>
  );
};

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const numberFormatter = new Intl.NumberFormat('en-US', {
  maximumSignificantDigits: 20
});

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(4)
  },
  printDiv: {
    // maxWidth: 1500,
    padding: theme.spacing(4)
    // paddingBottom: 250
  },
  gridAlt: {
    padding: theme.spacing(4),
    borderBottomColor: theme.palette.tertiary.dark,
    borderBottom: '2px solid'
  },
  grid: {
    padding: theme.spacing(4)
  },
  underline: {
    textDecoration: 'underline'
  },
  leftLabel: {
    width: 100
  }
}));

const LabelValue = (props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Typography variant={'subtitle1'} style={{ marginRight: 5 }}><b>{props.label}:</b></Typography>
      <Typography variant={'subtitle1'}>{props.value}</Typography>
    </div>
  );
};

LabelValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

const fileAPIUrl = `${process.env.REACT_APP_NSD_WEBSITE_API_BASE_URL}/file`;

const PurchaseOffer = (props) => {
  const classes = useStyles();
  const { formData } = props;

  const [offer, setOffer] = useState({
    vpOffer: 0,
    contractId: 'VP00001',
    vpAmount: 0,
    contractStartDate: moment(new Date()).format('L'),
    contractDataExpirationDate: moment(new Date()).add(10, 'days').format('L')
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
    setOpen(true);
    return () => setOpen(false);
  });

  useEffect(() => {
    let options = [formData.contract.covLimit];

    if (formData.tradeIn - formData.acv > 0) {
      options.push(formData.tradeIn - formData.acv);
    }
    if (formData.claimCost > 0) {
      options.push(formData.claimCost);
    }

    let vpAmount = first(orderBy(options, Math.floor));
    // let vpAmount = formData.tradeIn - formData.acv > 0 ? formData.tradeIn - formData.acv : 0;
    let vpOffer = vpAmount + formData.acv;
    setOffer(offer => ({
      ...offer,
      vpAmount,
      vpOffer
    }));

    let fileFormData = new FormData();
    fileFormData.append('file', formData.shopInvoice);
    fetch(`${fileAPIUrl}?fileName=${formData.shopInvoice?.name}`, {
      method: 'PUT',
      headers: { ...nsdApiKey },
      body: fileFormData
    })
      .then((res, othere) => {
        return res.json();
      })
      .then(
        (result) => {

        },
        (error, other) => {

        }
      )
      .catch((error) => {

      });
  }, []);

  return (
    <div className={classes.root}>
      {open &&
      <PDFDownloadLink document={<MyDocument formData={formData} offer={offer}/>} fileName={`${offer.contractId}.pdf`}>
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : (
          <Button startIcon={<PrintIcon/>} variant={'outlined'}>Print</Button>))}
      </PDFDownloadLink>}
      <div id="html-2-pdfwrapper" className={classes.printDiv}>
        <Grid container spacing={10}>
          <Grid item xs={12} style={{ marginBottom: 100 }}>
            <Grid container>
              <Grid item xs={4} style={{ width: '100%' }}></Grid>
              <Grid item xs={4}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignContent: 'flex-end',
                      alignItems: 'flex-end'
                    }}>
                <Typography variant={'h2'} className={classes.underline}>Purchase Offer</Typography>
              </Grid>
              <Grid item xs={4}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignContent: 'flex-end',
                      alignItems: 'flex-end'
                    }}>
                <img src={'/images/logos/logo.png'} width={200}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }} className={classes.gridAlt}>
            <Grid container alignItems={'center'} alignContent={'center'} justify={'center'} direction={'row'}
                  spacing={2}>
              <Grid item xs={6}>
                <LabelValue label={'Contract Number'} value={formData.contract.polMember}/>
                <LabelValue label={'Contract Date'} value={moment(formData.contract.effDt).format('L')}/>
                <LabelValue label={'Year, Make, Model, VIN#'}
                            value={`${formData.vehicleData.year}, ${formData.vehicleData.make}, ${formData.vehicleData.model}, ${formData.vehicleData.vin}`}/>
              </Grid>
              <Grid item xs={6}>
                <LabelValue label={'Offer ID'} value={offer.contractId}/>
                <LabelValue label={'Offer Date'} value={offer.contractStartDate}/>
                <LabelValue label={'Valid Until'} value={offer.contractDataExpirationDate}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 30 }} className={classes.gridAlt}>
            <Typography variant={'h3'} className={classes.underline}>CUSTOMER</Typography>
            <br/>
            <Grid container>
              <Grid item xs={6}>
                <LabelValue label={'Name'} value={`${formData.contract.polFname} ${formData.contract.polLname}`}/>
                <LabelValue label={'Address'}
                            value={`${formData.contract.polAddress} ${formData.contract.polAddress2 ? formData.contract.polAddress2 : ''} ${formData.contract.polCity}, ${formData.contract.polState} ${formData.contract.polZip}`}/>
                <LabelValue label={'Phone Number'} value={formatPhoneNumber(formData.contract.polPhone)}/>
              </Grid>
              <Grid item xs={6}>
                <LabelValue label={'Dealership Name'} value={formData.dealershipName}/>
                <LabelValue label={'Dealer Code'} value={formData.dealershipCode}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 30 }} className={classes.gridAlt}>
            <Typography variant={'h3'} className={classes.underline}>OFFER DETAILS</Typography>
            <br/>
            <Grid container>
              {
                formData.reportedAccident === 'yes' ?
                  <Grid item xs={6}>
                    <LabelValue label={'Reported Accident'} value={formData.reportedAccident.toUpperCase()}/>
                    <LabelValue label={'Accident Date'} value={moment(formData.accidentDate).format('L')}/>
                    <LabelValue label={'Repair Amount'} value={`${moneyFormatter.format(formData.claimCost)}`}/>
                    <LabelValue label={'Claim Detail'} value={formData.shopInvoice?.name || ''}/>
                    <LabelValue label={'Mileage'} value={numberFormatter.format(formData.mileage)}/>
                  </Grid>
                  :
                  <Grid item xs={6}>
                    <LabelValue label={'Reported Accident'} value={formData.reportedAccident.toUpperCase()}/>
                    <LabelValue label={'Accident Date'} value={''}/>
                    <LabelValue label={'Repair Amount'} value={''}/>
                    <LabelValue label={'Mileage'} value={numberFormatter.format(formData.mileage)}/>
                  </Grid>
              }
              <Grid item xs={6}>
                <LabelValue label={'Dealer’s Offer'} value={`${moneyFormatter.format(formData.acv)}`}/>
                <LabelValue label={'Average Trade-In'} value={`${moneyFormatter.format(formData.tradeIn)}`}/>
                <LabelValue label={'ValueProtect® Amount'} value={`${moneyFormatter.format(offer.vpAmount)}`}/>
                <LabelValue label={'ValueProtect® Offer'} value={`${moneyFormatter.format(offer.vpOffer)}`}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 30 }} className={classes.gridAlt}>
            <Typography variant={'subtitle1'} style={{ textAlign: 'center' }}>
              I verify that the information presented is accurate to the best of my
              knowledge. The required documentation will be forwarded to the Program
              Administrator according to the instructions below. Failure to accurately state the condition or value of
              the
              vehicle or to remit the required documentation may result in the <b>ValueProtect®</b> offer being
              rescinded.
              Submitted by {formData.dealershipName} / {formData.submittedBy} / {formData.title} </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 30 }} className={classes.gridAlt}>
            <Typography variant={'h3'} className={classes.underline}>
              DEALER REIMBURSEMENT INSTRUCTIONS
            </Typography>
            <br/>
            <Typography variant={'subtitle1'}>
              Please submit the required documentation listed below to the Program Administrator:
              <br/>
              <ol type={'A'} style={{ marginLeft: 20 }}>
                <li>
                  Proof of the reported accident:
                  <ol type={'1'} style={{ marginLeft: 20 }}>
                    <li>
                      Copy of the CARFAX® (or other VIN history) report OR
                    </li>
                    <li>
                      Copy of the insurance claim OR
                    </li>
                    <li>
                      Copy of the police report.
                    </li>
                  </ol>
                </li>
                <li>
                  Proof of repair amount:
                  <ol type={'1'} style={{ marginLeft: 20 }}>
                    <li>
                      Copy of the insurance claim OR
                    </li>
                    <li>
                      Copy of the body shop invoice.
                    </li>
                  </ol>
                </li>
                <li>
                  Print out of the Average Trade-in value from KBB, NADA, or Black Book. (Amount to be verified by
                  Administrator).
                </li>
                <li>
                  Copy of the <b>ValueProtect®</b> Purchase Offer (this document - please retain a copy for your
                  records)
                </li>
                <li>
                  Copy of the signed buyer’s order for the replacement vehicle OR if the customer did not purchase
                  another
                  vehicle, a copy of the signed odometer statement and proof of title reassignment. Payment will be
                  received within 7 days.
                </li>
              </ol>
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 30 }} className={classes.gridAlt}>
            <Typography variant={'subtitle1'} style={{ textAlign: 'center' }}>
              <Typography variant={'h3'} className={classes.underline}>
                ASSIGNMENT OF CONTRACT
              </Typography>
              FOR VALUE RECEIVED, Value Guard, LLC (as the undersigned Assignor) hereby assigns, transfers and sets
              over
              all of its rights, title, and interest in and to the Agreement (<b>ValueProtect®</b> Contract #) to the
              undersigned
              Assignee, who assumes this Agreement and agrees to purchase or accept as a trade in the Optioned Vehicle
              on
              behalf of Assignor.
              <br/>
              Signed this _________ day of ____________________, 20_____.
              <br/>
              Assignor: ________________________________________ Assignee (Dealer):
              ___________________________________________
              <br/>
              <b>
                Program Administrator:
                <br/>
                NSD Administrators
                800 West Yamato Road, Suite 100 • Boca Raton, FL 33431
                <br/>
                Phone: 1-888-684-9327 • <a
                href={'mailto:vprotect@nationsafedrivers.com'}>vprotect@nationsafedrivers.com</a>
              </b>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

PurchaseOffer.propTypes = {
  formData: PropTypes.object
};

export default PurchaseOffer;
