import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const SecondPage = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      SecondPage
    </div>
  );
};

SecondPage.propTypes = {};

export default SecondPage;
