import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { MainPage } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 550
  },
  content: {
    flex: 1,
    padding: theme.spacing(4),
    paddingLeft: theme.spacing(25),
    paddingRight: theme.spacing(25)
  }
}));

const CustomerInstructions = (props) => {
  const classes = useStyles();

  return (
    <MainPage
      title={'Customer Instructions'}
      subTitle={'Please refer to your ValueProtect agreement for detailed instructions.'}
      topImage={'/images/backgrounds/contact-cust-instructions-1920x600.jpg'}
      contentArray={[
        (
          <div>
            <Typography>
              If your vehicle was involved in an accident, please be sure to bring the following documents to your
              ValueProtect
              dealer when you are ready to trade your vehicle:
            </Typography>
            <br/>
            <Typography variant={'h5'}>
              Documentation Confirming The Repair Amount
            </Typography>
            <ul style={{ marginLeft: 30 }}>
              <li>
                <Typography>
                  A copy of the paid body invoice OR a copy of the insurance claim;
                </Typography>
              </li>
            </ul>
            <Typography variant={'h5'}>
              Documentation Confirming The Accident Date
            </Typography>
            <ul style={{ marginLeft: 30 }}>
              <li>
                <Typography>
                  A copy of police report, insurance claim, or body shop invoice..
                </Typography>
              </li>
            </ul>
          </div>
        )
      ]}
    />
  );
};

CustomerInstructions.propTypes = {};

export default CustomerInstructions;
;
