import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  TextField,
  Typography
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
      root: {
        width: '100%',
        height: '100%',
        '-webkit-backgroundSize': 'cover',
        '-moz-backgroundSize': 'cover',
        '-o-backgroundSize': 'cover',
        'backgroundSize': 'cover',
        background: 'url(\'/images/backgrounds/cloud-computing-2001090_1920.4e494bed.jpg\') no-repeat center center fixed'

      }
    }
  )
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={{ enter: 1000, exit: 1000 }}/>;
});

const Login = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <div>
        {/*<img width={'100%'} src={'/images/backgrounds/cloud-computing-2001090_1920.4e494bed.jpg'} alt={'background'}/>*/}
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        fullScreen={fullScreen}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant={'subtitle1'}>
              Log in to continue to:
              <br/>
              Dealer Login
            </Typography>
            <br/>
            <br/>
            <TextField
              label={'Password'}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Button onClick={handleClose} variant={'outlined'} color="primary">
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Login.propTypes = {};

export default Login;
