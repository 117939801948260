import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import Routes from './Routes';
import { MuiThemeProvider } from '@material-ui/core';

const browserHistory = createBrowserHistory();


export default class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <Router history={browserHistory}>
            <Routes/>
          </Router>
        </ThemeProvider>
      </MuiThemeProvider>
    );
  }
}
